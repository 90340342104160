import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { css } from 'twin.macro';
import DateTimePicker from 'react-datetime-picker';
import { useLocation } from 'react-router-dom';
import SearchByProvider from '../EvvSearchModal/SearchByProvider';
import ZealReports from './ZealComponents/Reports';
import { fetchWrapper, getTeamIdFromURL, toastWrapper } from '../../../../_helpers';
import ZealReportSelect from './ZealComponents/Reports/ZealReportSelect';

const Reports = ({ teams }) => {
    const isTeamSettings = !!useLocation().pathname.match(/user-evv/);
    const [provider, setProvider] = useState({});
    const [dateFilters, setDateFilters] = useState({
        start: new Date(Date.now() - 30 * 86400 * 1000),
        end: new Date(),
    });
    const [selectedReportOption, setSelectedReportOption] = useState({});

    const handleProviderSelect = (selectedProvider) => {
        setProvider(selectedProvider);
    };
    const handleReportSelect = (selectedReport) => {
        setSelectedReportOption(selectedReport);
    };

    const teamId = getTeamIdFromURL();
    useEffect(() => {
        if (!isTeamSettings || !teamId) {
            return null;
        }
        const team = teams.find((team) => team.id === teamId);
        fetchWrapper
            .get(`evv/provider?team=${teamId}`)
            .then(({ results: [response] }) =>
                setProvider({
                    value: response?._id,
                    label: response?.agencyName,
                    providerId: response?._id,
                    teamId: team?.id,
                    agencyName: response?.agencyName,
                    team: response?._id,
                    state: response?.state,
                    payroll: response?.payroll,
                })
            )
            .catch((error) => {
                if (error.name === 'AbortError') {
                    return;
                }
                toastWrapper.error("Couldn't fetch provider data");
            });
    }, [teams, isTeamSettings]);

    return (
        <div>
            <div
                className="d-flex flex-row align-items-end justify-content-between mb-4"
                css={css`
                    gap: 12px;
                `}
            >
                <div
                    className="d-flex"
                    css={css`
                        gap: 12px;
                        @media (max-width: 1200px) {
                            flex-direction: column;
                        }
                        flex-direction: row;
                    `}
                >
                    <div
                        css={css`
                            min-width: 270px;
                        `}
                    >
                        <SearchByProvider
                            provider={provider}
                            handleSelect={handleProviderSelect}
                            isDisabled={isTeamSettings}
                            team={teamId}
                        />
                    </div>
                    <div
                        className="d-flex flex-row align-items-end"
                        css={css`
                            gap: 12px;
                        `}
                    >
                        <div
                            css={css`
                                width: 270px;
                            `}
                        >
                            {{
                                zeal: (
                                    <ZealReportSelect
                                        providerPayroll={provider?.payroll}
                                        selectedReportOption={selectedReportOption}
                                        handleSelectedReport={handleReportSelect}
                                    />
                                ),
                            }[provider?.payroll?.type] || <span></span>}
                        </div>
                        {!selectedReportOption?.date ? null : (
                            <Fragment>
                                <div>
                                    <span>Start Date:</span>
                                    <br />
                                    <DateTimePicker
                                        onChange={(start) => {
                                            setDateFilters((prev) => ({ ...prev, start }));
                                        }}
                                        value={dateFilters.start}
                                        format="MM-dd-y"
                                        disableClock={true}
                                        calendarType={'US'}
                                        css={css`
                                            background: white;
                                            height: 36px;
                                        `}
                                    />
                                </div>
                                <div>
                                    <span>End Date:</span>
                                    <br />
                                    <DateTimePicker
                                        onChange={(end) => {
                                            setDateFilters((prev) => ({ ...prev, end }));
                                        }}
                                        value={dateFilters.end}
                                        format="MM-dd-y"
                                        disableClock={true}
                                        calendarType={'US'}
                                        css={css`
                                            background: white;
                                            height: 36px;
                                        `}
                                    />
                                </div>
                            </Fragment>
                        )}
                    </div>
                </div>
                <span
                    className="badge badge-info"
                    css={css`
                        font-size: 16px !important;
                    `}
                >
                    {provider?.payroll?.type}
                </span>
            </div>
            <div
                className="custom-card d-flex flex-col"
                css={css`
                    min-height: 70vh;
                    overflow: auto;
                    position: relative;
                    justify-content: center;
                `}
            >
                {!provider?.value ? (
                    <div>Please select a provider</div>
                ) : (
                    {
                        zeal: (
                            <ZealReportView
                                providerPayroll={provider?.payroll}
                                teamId={teamId}
                                dateFilters={dateFilters}
                                selectedReportOption={selectedReportOption}
                            />
                        ),
                    }[provider?.payroll?.type] || (
                        <div>Payroll process has not been set up for {provider?.payroll?.type}</div>
                    )
                )}
            </div>
        </div>
    );
};

function ZealReportView({ providerPayroll, teamId, selectedReportOption, dateFilters }) {
    const isProviderOnboarded = providerPayroll?.metadata?.companyID && providerPayroll?.metadata?.status === 'live';

    if (!isProviderOnboarded) {
        return <div>Provider is not onboarded.</div>;
    }
    return (
        <div style={{ width: '100%' }}>
            <ZealReports
                provider={providerPayroll}
                teamId={teamId}
                selectedReportOption={selectedReportOption}
                dateFilters={dateFilters}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
});

export default connect(mapStateToProps)(Reports);
