import { css } from 'twin.macro';
import { useState, useRef,  useEffect, Fragment } from 'react';
import { Row, Col, Modal, Button, Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
// import PropTypes from 'prop-types';
import { fetchWrapper, toastWrapper } from '../../../_helpers';

import FormFilterModal from './FormFilterModal';
import ViewForm from '../../../components/Admin/Form/ViewForm';

import Pagination from '../Pagination';
import Loader from '../containers/Loader';
import { SubmittedFormStyled } from './styles/SubmittedFormStyled';
import NavigationBar from '../reusableComponents/NavigationBar';
import AdminSearchBox from '../reusableComponents/AdminSearchBox';
import { ActionStyles, AdminTable } from '../reusableStyles';
import Avatar from '../reusableComponents/Avatar';
import WelcomeUser from '../reusableComponents/WelcomeUser';
import DeleteForms from './DeleteForms';
import TeamSelectFilter from '../TeamSelectFilter';
import { TagPreviewWithContainer } from '../../Features/Common/Tags/TagsPreview';


//icons
import { BsEye } from 'react-icons/bs';
import { ToolTip } from '../../../_elements/ToolTip';

export const ViewUserForm = ({ id, children, form }) => {
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	return (
		<Fragment>
			{children ? <span onClick={handleShow}>{children}</span> :<ToolTip text={'View'} cssStyles={"top:-70% !important; min-width: fit-content !important;"}>  <BsEye onClick={handleShow} className="icon" /> </ToolTip>}
			<Modal show={show} onHide={handleClose} size="lg" centered>
			<Modal.Header>
					<div>
						{form?.formData?.name}
						<TagPreviewWithContainer tags={form?.tags || []} />
					</div>
				</Modal.Header>
				<Modal.Body>
					<ViewForm formId={id} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	);
};

export const SubmittedForm = () => {
	const [filteredForms, setFilteredForms] = useState([]);
	const [search, setSearch] = useState('');
	const [isFiltered, setIsFiltered] = useState(false);
	const [filterUser, setFilterUser] = useState(null);
	const [filterChannel, setFilterChannel] = useState(null);
	const [filterStartDate, setFilterStartDate] = useState(null);
	const [filterEndDate, setFilterEndDate] = useState(null);
	const [filterQuery, setFilterQuery] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [totalForms, setTotalForms] = useState(11);
	const [currentPage, setCurrentPage] = useState(1);
	const [selectedTeamOption, setSelectedTeamOption] = useState({label:"Select team . . ."});
	const inputRef = useRef();
	const [actionPerformed,setActionPerformed] = useState(false)

	const onSearch = (val) => {
		setSearch(val.trimLeft());
	};

	useEffect(() => {
		let timer;
		const fetchData = async () => {
			setIsLoading(true);
			try {
				let queryString = `userFormData?sortBy=createdAt:desc&limit=10&page=${currentPage}${
					search ? `&name=${search}` : ''
				}${selectedTeamOption?.value ? `&team=${selectedTeamOption?.value}` : ''}`;
				const result = await fetchWrapper.get(`${queryString}${filterQuery ? filterQuery : ''}`);
				setFilteredForms(result.results);
				setTotalForms(result.totalResults);
				console.log("total formr",result.totalResults)
			} catch (error) {
				console.log(error);
				toastWrapper.error(error?.message || `Something went wrong while fetching Submitted forms`);
			} finally {
				setIsLoading(false);
				
			}
		};
		clearTimeout(timer);
		timer = setTimeout(fetchData, 500);
		return () => clearTimeout(timer);
	},[search, currentPage, filterQuery, selectedTeamOption,actionPerformed]);


	const onPageChanged = ({ currentPage }) => {
		setCurrentPage(Math.max(currentPage || 1, 1));
	};
	const fetchOnActionPerformed = ()=>{
		setCurrentPage(1)
		setActionPerformed(prev=>!prev)
	}
	const handleFormDelete = async (formId) => {
		try {
			fetchWrapper.delete(`userFormData/${formId}`).then((res) => {
				toast.success('Form deleted successfully');
				fetchOnActionPerformed()
			});
		} catch (error) {
			toast.error('Something went wrong');
		}
	};

	const dateFormat = (item) => {
		const newdate = new Date(item?.createdAt).toLocaleString();
		const dateTime = newdate.split(',');
		const date = dateTime[0];
		let time = dateTime[1].split(' ')[1].split(':');
		time = time[0] + ':' + time[1] + ' ' + dateTime[1].split(' ')[2];
		return (
			<td className="text-left">
				{item?.createdAt && (
					<div className="d-flex flex-column justify-content-center">
						<span>{date}</span>
						<small
							className="muted"
							css={css`
								color: #737373;
							`}
						>
							{time}
						</small>
					</div>
				)}
			</td>
		);
	};

	const removeFilter = () => {
		setIsFiltered(false);
		setFilterUser(null);
		setFilterQuery(null);
		setFilterChannel(null);
		// setFilterText(null);
		setFilterStartDate(null);
		setFilterEndDate(null);
		// filterFetchForms();
		fetchOnActionPerformed()
	};

	const handleFilterSubmit = ({
		userValue,
		channelValue,
		// searchText,
		startDateTime,
		endDateTime,
	}) => {
		let qry = '';
		if (userValue) {
			let user = userValue.split('-');
			let userId = user[1];
			let userName = user[0];
			setFilterUser(userName);
			qry = qry + `&user=${userId}`;
		}
		if (channelValue) {
			let channel = channelValue.split('-');
			let channelId = channel[1];
			let channelName = channel[0];
			setFilterChannel(channelName);
			qry = qry + `&chatroom=${channelId}`;
		}
		// if (searchText) {
		// 	setFilterText(searchText);
		// 	qry = qry + `&searchText=${searchText}`;
		// }
		if (startDateTime) {
			const newStartDateTime = new Date(startDateTime).toISOString();
			setFilterStartDate(new Date(startDateTime).toLocaleString());
			qry = qry + `&startDate=${newStartDateTime}`;
		}
		if (endDateTime) {
			const newEndDateTime = new Date(endDateTime).toISOString();
			setFilterEndDate(new Date(endDateTime).toLocaleString());
			qry = qry + `&endDate=${newEndDateTime}`;
		}
		setFilterQuery(qry);
		setIsFiltered(true);
	};
	return (
		<SubmittedFormStyled>
			<div className="d-flex justify-content-between mb-4">
				<NavigationBar mainTabName="Submitted Forms" />
				<WelcomeUser />
			</div>
			<div className="d-flex flex-row justify-content-between align-items-center mb-4 w-100">
				<div
					className="d-flex flex-row align-items-center"
					css={css`
						gap: 1rem;
					`}
				>
					<AdminSearchBox
						search={search}
						onSearch={onSearch}
						placeholder="Search Submitted Forms"
						inputRef={inputRef}
					/>
					<TeamSelectFilter
						selectedTeamOption={selectedTeamOption}
						setSelectedTeamOption={setSelectedTeamOption}
					/>
				</div>
				<FormFilterModal
					handleFilterSubmit={handleFilterSubmit}
					filterUser={filterUser}
					filterChannel={filterChannel}
					// filterText={filterText}
					filterStartDate={filterStartDate}
					filterEndDate={filterEndDate}
				/>
			</div>

			{isFiltered && (
				<div className="filtersDiv d-flex flex-row justify-content-between align-items-center">
					{filterUser && (
						<div>
							User:
							<Badge pill variant="primary" className="filterbadge">
								{filterUser}
							</Badge>
						</div>
					)}
					{filterChannel && (
						<div>
							Channel:
							<Badge pill variant="primary" className="filterbadge">
								{filterChannel}
							</Badge>
						</div>
					)}
					{/* {filterText && (
						<div>
							Text in form:
							<Badge pill variant="primary" className="filterbadge">
								{filterText}
							</Badge>
						</div>
					)} */}

					{filterStartDate && (
						<div>
							After:
							<Badge pill variant="primary" className="filterbadge">
								{filterStartDate}
							</Badge>
						</div>
					)}

					{filterEndDate && (
						<div>
							Before:
							<Badge pill variant="primary" className="filterbadge">
								{filterEndDate}
							</Badge>
						</div>
					)}
					<div>
						<Button variant="secondary" onClick={() => removeFilter()}>
							Remove Filters
						</Button>
					</div>
				</div>
			)}

			<Fragment>
				<Row>
					<Col>
						<div className="custom-card card-table d-flex flex-column justify-content-between">
							<AdminTable className="w-100 text-dark-grey">
								<thead>
									<tr>
										<th>S.N.</th>
										<th className="text-left">Form Name</th>
										<th className="text-left">Submitted at</th>
										<th
											className="text-left"
											css={css`
												padding-left: 15px;
											`}
										>
											User
										</th>
										<th className="text-left">Channel</th>
										<th className="text-center">Action</th>
									</tr>
								</thead>
								<tbody className="text-light-grey">
									{isLoading ? (
										<tr><td>
										<div className="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
											<Loader size="sm" top={250} />
										</div>
										</td></tr>
									) : filteredForms && filteredForms.length > 0 ? (
										filteredForms.map((item, i) => {
											// if (i < 10) {
											return (
												<tr key={item._id} className="p-4">
													<td>{(currentPage - 1) * 10 + (i + 1)}</td>
													<td className="text-left">{item?.formData?.name}</td>
													{dateFormat(item)}

													<td>
														<div
															className={`${
																item?.userData?.name ? '' : 'text-danger'
															} d-flex flex-row align-items-center`}
														>
															<Avatar item={item?.userData} />
															<span className="ml-2">
																{item?.userData?.name
																	? item?.userData?.name
																	: 'Deleted User'}
															</span>
														</div>
													</td>
													<td
														className={`${
															item?.chatroomData?.title ? '' : 'text-danger'
														} text-left`}
														css={css`
															max-width: 300px;
														`}
													>
														{item?.chatroomData?.title
															? item?.chatroomData?.title
															: 'Channel Deleted'}
													</td>
													<td>
													<ActionStyles>
														<ViewUserForm id={item?._id} />
														<DeleteForms
															handleDelete={handleFormDelete}
															item={item}
															type="submitted"
														/>
													</ActionStyles>
													</td>
													<td className="verification-status"></td>
												</tr>
											);
											// } else {
											// 	return null;
											// }
										})
									) : (
										<tr>
										<td colSpan={6}>
											<div
												className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
												css={css`
													margin-top: 50px;
													width: 100%;
												`}
											>
												No Forms Found
											</div>
										</td>
										</tr>
									)}
								</tbody>
							</AdminTable>

							<div className="p-4">
								<Pagination
									totalRecords={totalForms}
									key={totalForms}
									pageLimit={10}
									pageNeighbours={1}
									onPageChanged={onPageChanged}
								/>
							</div>
						</div>
					</Col>
				</Row>
			</Fragment>
		</SubmittedFormStyled>
	);
};

SubmittedForm.propTypes = {};

const mapStateToProps = (state) => ({
	teams: state.teams.teams,
});
export default connect(mapStateToProps, {})(SubmittedForm);
