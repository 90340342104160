import React, { useEffect, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { css } from 'twin.macro';
import AccrualPolicySchema from './AccrualPolicySchema.json';
import { fetchWrapper, pickObjectKeys } from '../../../../../../_helpers';
import { toast } from 'react-toastify';
import { VscLoading } from 'react-icons/vsc';

export function getFormLabel(name, required = false) {
    const [initial, ...other] = (name || 'Unnamed Field')
        .replace(/([a-z0-0]_[a-z0-9])/g, (v) => `${v[0]} ${v[2].toUpperCase()}`)
        .replaceAll('_', ' ')
        .split('');
    return (
        <span>
            {initial.toUpperCase() + other.join('').replaceAll(/([a-z])([A-Z])/g, '$1 $2')}
            {required ? (
                <span
                    css={css`
                        color: red;
                        margin-left: 3px;
                    `}
                >
                    *
                </span>
            ) : null}
        </span>
    );
}

const formInputFields = [
    'policy_code',
    'policy_type',
    'policy_name',
    'policy_effective_date',
    'accrual_rate_hours',
    'accrual_period_hours',
    'immediate_balance',
    'include_doubletime',
    'include_overtime',
    'accrual_waiting_period',
    'accrual_cap',
    'rollover_cap',
    'rollover_date',
    'policy_status',
];

const AccrualForm = ({ companyID, goBack, initialValues = {}, handleCreateOrUpdate }) => {
    const [fieldValues, setFieldValues] = useState(pickObjectKeys(initialValues, formInputFields));
    const [errors, setErrors] = useState({});
    const [isBusy, setIsBusy] = useState(false);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        setFieldValues({
            ...fieldValues,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsBusy(true);
        try {
            const isUpdate = initialValues?.policy_code;
            let response;
            if (isUpdate) {
                response = await fetchWrapper.patch(
                    `evv/payroll/zeal/provider/${companyID}/accrual-policy`,
                    fieldValues
                );
            } else {
                response = await fetchWrapper.post(
                    `evv/payroll/zeal/provider/${companyID}/accrual-policy`,
                    fieldValues
                );
            }
            toast.success(`Accrual Policy ${fieldValues?.policy_name || ''} ${isUpdate ? 'Updated' : 'Created'}`);
            handleCreateOrUpdate(response);
            goBack();
        } catch (err) {
            console.log('err', err);
            toast.error(err?.message || 'Something went wrong.');
        } finally {
            setIsBusy(false);
        }
    };
    useEffect(() => {
        setFieldValues(pickObjectKeys(initialValues, formInputFields));
    }, [initialValues]);
    return (
        <Form
            onSubmit={handleSubmit}
            css={css`
                margin-top: 16px;
            `}
        >
            {Object.keys(AccrualPolicySchema.properties)
                .filter((_key) => initialValues?.policy_code || _key !== 'policy_status')
                .map((key) => {
                    const field = AccrualPolicySchema.properties[key];
                    return (
                        <Form.Group
                            key={key}
                            css={css`
                                margin-bottom: 16px;
                            `}
                        >
                            <Form.Label
                                css={css`
                                    font-weight: 600;
                                `}
                            >
                                {field.type === 'boolean' ? null : <div>{getFormLabel(key, field.required)}</div>}
                            </Form.Label>
                            {field.type === 'string' && field.enum ? (
                                <Form.Control
                                    as="select"
                                    name={key}
                                    value={fieldValues[key] || ''}
                                    onChange={handleChange}
                                    required={field.required}
                                >
                                    <option value="">Select...</option>
                                    {field.enum.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </Form.Control>
                            ) : field.type === 'string' && field.format === 'date' ? (
                                <Form.Control
                                    type="date"
                                    name={key}
                                    value={fieldValues[key] || ''}
                                    placeholder={field.placeholder}
                                    onChange={handleChange}
                                    required={field.required}
                                />
                            ) : field.type === 'boolean' ? (
                                <Form.Check
                                    type="checkbox"
                                    label={getFormLabel(key, field.required)}
                                    name={key}
                                    checked={fieldValues[key] || false}
                                    onChange={handleChange}
                                    required={field.required}
                                    css={css`
                                        input[type='checkbox'] {
                                            transform: scale(1.5);
                                        }
                                        margin-left: 8px;
                                    `}
                                />
                            ) : (
                                <Form.Control
                                    type={field.type === 'number' ? 'number' : 'text'}
                                    name={key}
                                    value={fieldValues[key] || ''}
                                    placeholder={field.placeholder}
                                    onChange={handleChange}
                                    required={field.required}
                                    pattern={field.pattern}
                                    css={css`
                                        ::placeholder {
                                            color: #999;
                                        }
                                    `}
                                    onInvalid={(e) => {
                                        setErrors((errorsObj) => ({ ...(errorsObj || {}), [key]: field.errormessage }));
                                        e.target.setCustomValidity(e.target.validationMessage);
                                    }}
                                    onInput={(e) => {
                                        e.target.setCustomValidity('');
                                        setErrors({});
                                    }}
                                />
                            )}
                            {errors[key] ? (
                                <p
                                    css={css`
                                        font-size: 15px;
                                        color: #ff0000;
                                        padding: 0 4px;
                                    `}
                                >
                                    {errors[key]}
                                </p>
                            ) : null}
                            <span
                                css={css`
                                    font-size: 15px;
                                    color: #444;
                                    padding: 0 4px;
                                `}
                            >
                                {field?.description || ''}
                            </span>
                        </Form.Group>
                    );
                })}
            <div
                css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: sticky;
                    bottom: -12px;
                    background: white;
                    padding: 12px 0;
                `}
            >
                <hr
                    css={css`
                        width: 100%;
                    `}
                />
                <Button type="submit" disabled={isBusy}>
                    {isBusy && <VscLoading className="spin mr-2" />}
                    {initialValues?.policy_code ? 'Update Accrual Policy' : 'Create Accrual Policy'}
                </Button>
            </div>
        </Form>
    );
};

export default AccrualForm;
