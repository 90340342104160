import React, { Fragment, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Button, Form } from 'react-bootstrap';
import OurAccordion from '../../../../_elements/OurAccordion';
import { fetchWrapper } from '../../../../_helpers';
import { toast } from 'react-toastify';
import ZealEmployeeCheckForm, { prepareFormData as prepareZealFormData } from './ZealComponents/ZealEmployeeCheckForm';
import EmployeeCheckRow from './ZealComponents/EmployeeCheckRow';
import { CheckError, PayHighlight } from './ZealComponents/ZealCompanyPayroll';
import Swal from 'sweetalert2';
import EmployeeCheckPreviewModal from './ZealComponents/EmployeeCheckPreviewModal';
import { TfiReload } from 'react-icons/tfi';

function isEmployeeOnboarded(employee) {
    if (
        employee?.payroll?.type === 'zeal' &&
        employee?.payroll?.metadata?.employeeID &&
        employee?.payroll?.metadata?.onboarded
    ) {
        return true;
    }
    return false;
}

function prepareFormData(payrollType, formData) {
    return { zeal: prepareZealFormData }[payrollType](formData) || (() => formData);
}

function PayrollBillModal({
    selectedVisits,
    gotoScreenOne,
    provider,
    teamId,
    workLocations = [],
    defaultReportingDataQuery = {},
}) {
    const [isBusy, setIsBusy] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [initialLoading, setInitialLoading] = useState(true);
    const [showBulkPreview, setShowBulkPreview] = useState(false);
    const [previewError, setPreviewError] = useState('');
    const [initialReportingData, setInitialReportingData] = useState([]);
    const [bulkPreviewDataFromZeal, setBulkPreviewDataFromZeal] = useState([]);
    const companyID = provider?.payroll?.metadata?.companyID;
    const [defaultCheckDate, setDfaultCheckDate] = useState(
        new Date(Date.now() + 2 * 86400 * 1000).toISOString().slice(0, 10)
    );
    const onHide = () => {
        setShowBulkPreview(false);
    };
    const onUpdateEmployeesPreviewData = (updatedData) => {
        setBulkPreviewDataFromZeal((prev) => {
            return (prev || [])?.map((employeeRowData) => {
                if (updatedData?.employeeID === employeeRowData?.employeeID) {
                    return { ...employeeRowData, ...updatedData };
                }
                return { ...employeeRowData };
            });
        });
    };

    async function togglePreviewModal() {
        setShowBulkPreview(true);
        fetchPreviewDataArr();
    }

    function groupFunction(data) {
        const aggregatedData = {};
        (data || [])?.forEach((visitItem) => {
            const employeeId = visitItem?.employee?._id;
            const visitEndTime = new Date(visitItem?.ScheduleEndTime || Date.now());
            const visitStartTime = new Date(visitItem?.ScheduleStartTime || Date.now());
            const totalHours = visitItem?.extraInfo?.staffRate
                ? (visitItem?.extraInfo?.staffAmount || 0) / visitItem?.extraInfo?.staffRate
                : 0;
            if (totalHours <= 0) {
                return;
            }
            const hourlyShift = {
                type: 'hourly',
                start: visitStartTime,
                end: visitEndTime,
                totalHours,
                rate: visitItem?.extraInfo?.staffRate,
                visitId: visitItem?._id,
            };
            const reimbursementShift =
                visitItem?.extraInfo?.mileageData && visitItem?.provider?.payroll?.metadata?.mileageReimbursementRate
                    ? {
                          type: 'reimbursement',
                          start: visitStartTime,
                          end: visitEndTime,
                          amount:
                              ((visitItem?.extraInfo?.mileageData?.distance || 0) *
                                  visitItem?.provider?.payroll?.metadata?.mileageReimbursementRate) /
                              1609.34,
                          visitId: visitItem?._id,
                      }
                    : null;
            const shifts = [hourlyShift, reimbursementShift].filter((shift) => {
                if (shift?.type === 'reimbursement' && shift?.amount < 0.005) {
                    return false;
                }
                return !!shift;
            });

            if (!aggregatedData[employeeId]) {
                aggregatedData[employeeId] = {
                    totalAmount: (visitItem?.extraInfo?.staffAmount || 0) + (reimbursementShift?.amount || 0),
                    totalRate: visitItem?.extraInfo?.staffRate,
                    totalHours,
                    shifts,
                    count: 1,
                    ...visitItem,
                };
            } else {
                aggregatedData[employeeId] = {
                    totalAmount: aggregatedData[employeeId]?.totalAmount + visitItem?.extraInfo?.staffAmount,
                    totalRate: aggregatedData[employeeId]?.totalRate + visitItem?.extraInfo?.staffRate,
                    count: aggregatedData[employeeId]?.count + 1,
                    totalHours: totalHours + aggregatedData[employeeId]?.totalHours,
                    shifts: [...aggregatedData[employeeId].shifts, ...shifts],
                    ...visitItem,
                };
            }
        });

        return Object.keys(aggregatedData).map((employeeId) => ({
            ...aggregatedData[employeeId],
        }));
    }

    const groupedVisits = groupFunction(selectedVisits);

    const [selectedStaffAmount, setSelectedStaffAmount] = useState(
        groupedVisits?.map((employeeVisitsData) => ({
            employeeID: employeeVisitsData?.employee?.payroll?.metadata?.employeeID,
            amount: 0,
        }))
    );

    const onboardedEmployeeIDs = groupedVisits
        .map((visit) => (isEmployeeOnboarded(visit?.employee) ? visit?.employee?.payroll?.metadata?.employeeID : null))
        .filter((item) => item);
    async function getEmployeeCheckPreview(checkData) {
        try {
            setIsBusy(true);
            const data = await fetchWrapper.post(
                `/evv/payroll/zeal/employee/${companyID}/preview-check${teamId ? `?team=${teamId}` : ''}`,
                checkData
            );
            setPreviewError('');
            return data;
        } catch (error) {
            const errorMessage = error.message || "Preview data couldn't be fetched";
            toast.error(errorMessage);
            setPreviewError(errorMessage);
            return [];
        } finally {
            setIsBusy(false);
        }
    }

    async function onConfirmBulkCreateCheck() {
        const result = await Swal.fire({
            title: 'Do you confirm creating checks?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#50aeb0',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        });
        if (result?.isConfirmed) {
            await bulkUpdateEmployeePayrolls();
        }
    }
    async function bulkUpdateEmployeePayrolls() {
        try {
            setSubmitting(true);
            const checkDataArray = [];
            for (const employeeID of onboardedEmployeeIDs) {
                const result = await prepareAndFetchCheckData(employeeID);
                if (result) {
                    checkDataArray.push(result);
                }
            }
            const providerIdentifier =
                { zeal: provider?.payroll?.metadata?.companyID }[provider.payroll.type] || provider._id;
            const response = await fetchWrapper.post(
                `evv/payroll/${provider.payroll.type}/employee/${providerIdentifier}/checks${teamId ? `?team=${teamId}` : ''}`,
                checkDataArray
            );
            if (response?.createdChecks?.length) {
                toast.success(`Created ${response?.createdChecks?.length} checks with ${response?.visitCount} visits`);
            }
            if (response?.failedChecks?.length) {
                toast.error(`Failed to created ${response?.failedChecks?.length} checks`);
                (response?.failedChecks || [])?.forEach((errorResponse) => {
                    const errorMessage = errorResponse?.error?.message;
                    if (errorMessage) {
                        toast.error(errorMessage);
                    }
                });
            }
            if (response?.createdChecks?.length) {
                onHide();
                gotoScreenOne();
            }
        } catch (error) {
            console.log('ERROR', error);
            toast.error('Something went wrong!');
        } finally {
            setSubmitting(false);
        }
    }

    async function fetchReportingPeriods() {
        try {
            Swal.fire({
                html: `<div style="display: flex; justify-content: center; align-items: center;flex-direction:column;">
                            <img src="/images/loading.gif" alt="loader" />
                            <h3>Sequencing Checks ...</h3>
                        </div>`,
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                showConfirmButton: false,
            });
            setInitialLoading(true);
            if (!companyID) {
                return;
            }
            // if (mode === 'view' && reportingPeriods.length === 1) {
            //     return;
            // }
            const queryString = Object.entries(defaultReportingDataQuery)
                .map(([key, value]) => `${key}=${value}`)
                .join('&');
            const response = await fetchWrapper.get(
                `evv/payroll/zeal/${companyID}/reporting-periods?${queryString}${teamId ? `&team=${teamId}` : ''}`
            );
            setInitialReportingData(Array.isArray(response) ? response : [response]);
        } catch (error) {
            if (error?.name === 'AbortError') return;
            toast.error(error?.message || "Reporting Periods couldn't be fetched");
        } finally {
            setInitialLoading(false);
        }
    }

    async function prepareAndFetchCheckData(employeeID) {
        const formContainer = document.querySelector(`#employee-payroll-form-container-${employeeID}`);
        if (!formContainer) {
            console.error(`Form container for employeeID ${employeeID} not found.`);
            return null;
        }

        const form = formContainer.querySelector('form.employee-payroll-form');
        if (!form) {
            console.error(`Form for employeeID ${employeeID} not found.`);
            return null;
        }

        const formData = new FormData(form);
        const checkData = prepareFormData('zeal', formData);

        if (!checkData.reportingPeriodID) {
            console.warn(`No reportingPeriodID found for employeeID ${employeeID}. Skipping.`);
            return null;
        }
        return checkData;
    }

    async function fetchPreviewDataArr() {
        const checkDataArray = [];
        for (const employeeID of onboardedEmployeeIDs) {
            const result = await prepareAndFetchCheckData(employeeID);
            if (result) {
                checkDataArray.push(result);
            }
        }
        const response = await getEmployeeCheckPreview(checkDataArray);
        const responseWithShifts = checkDataArray.reduce((responseAcc, empCheckDetails) => {
            responseAcc[empCheckDetails?.employeeID] = { ...empCheckDetails, ...response[empCheckDetails?.employeeID] };
            return responseAcc;
        }, {});
        changeStaffAmount(Object.values(response) || []);
        setBulkPreviewDataFromZeal(Object.values(responseWithShifts) || []);
        Swal?.close();
    }

    function changeStaffAmount(previewData = []) {
        const data = (previewData || []).map((_employeeCheck) => {
            const amount = Number(_employeeCheck?.checks?.at(0)?.gross_pay);
            const employeeID = _employeeCheck?.employeeID;
            return { amount, employeeID };
        });
        setSelectedStaffAmount(data);
    }

    function getTotalGrossPay() {
        return selectedStaffAmount.reduce((acc, item) => acc + item?.amount || 0, 0)?.toFixed(2);
    }

    useEffect(() => {
        // fetch inital reporting period and pass down// initial reporting period data is same for all employees so no need to fetch for each
        fetchReportingPeriods();
    }, []);

    useEffect(() => {
        if (!initialLoading) {
            // After all the form data is rendered from our visit data from database, send those data to fetch actual data from zeal
            setTimeout(() => {
                fetchPreviewDataArr(true);
            }, 200);
        }
    }, [initialLoading]);

    return (
        <Fragment>
            <div
                css={css`
                    background: #fff;
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
                    padding: 12px;
                    height: 90vh;
                    position: relative;
                `}
            >
                <div
                    css={css`
                        max-width: 1024px;
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                        margin: auto;
                        overflow: auto;
                        height: 100%;
                    `}
                >
                    <div
                        css={css`
                            width: 100%;
                            padding: 0 12px;
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                gap: 12px;
                                align-items: center;
                                justify-content: flex-end;
                            `}
                        >
                            {/* <span>Payroll Bill</span> */}
                            <span className="badge badge-info badge-sm">{provider?.payroll?.type?.toUpperCase()}</span>
                        </div>
                    </div>
                    <div
                        css={css`
                            overflow: auto;
                            padding: 8px 12px;
                            border: 1px solid #eee;
                            margin-bottom: 48px;
                            height: 100%;
                        `}
                    >
                        {/* <div className="w-100 text-dark-grey"> */}
                        {groupedVisits?.length ? (
                            <div
                                css={css`
                                    display: flex;
                                    flex-direction: column;
                                    gap: 12px;
                                `}
                            >
                                <div
                                    css={css`
                                        display: flex;
                                        gap: 12px;
                                        align-items: center;
                                        justify-content: space-between;
                                    `}
                                >
                                    <Form.Group
                                        css={css`
                                            display: flex;
                                            gap: 12px;
                                            align-items: flex-end;
                                        `}
                                    >
                                        <Form.Label
                                            css={css`
                                                min-width: fit-content;
                                            `}
                                        >
                                            Default Check Date
                                        </Form.Label>
                                        <Form.Control
                                            type="date"
                                            name="default_check_date"
                                            disabled={isBusy}
                                            value={defaultCheckDate}
                                            onChange={(event) => setDfaultCheckDate(event.target.value)}
                                            required
                                        />
                                    </Form.Group>
                                </div>
                                {groupedVisits?.map((visit, idx) => (
                                    <PayrollBillEmployeeDetails
                                        key={idx}
                                        detailVisit={{ index: idx, ...visit }}
                                        defaultCheckDate={defaultCheckDate}
                                        teamId={teamId}
                                        workLocations={workLocations}
                                        changeStaffAmount={changeStaffAmount}
                                        initialReportingData={initialReportingData}
                                        bulkPreviewDataFromZeal={bulkPreviewDataFromZeal}
                                        onUpdateEmployeesPreviewData={onUpdateEmployeesPreviewData}
                                        defaultReportingDataQuery={defaultReportingDataQuery}
                                        getTotalGrossPay={getTotalGrossPay}
                                        gotoScreenOne={gotoScreenOne}
                                    />
                                ))}
                            </div>
                        ) : (
                            <div
                                className="w-100 h-100 d-flex flex-row justify-content-center align-items-center"
                                css={css`
                                    margin-top: 100px;
                                    width: 100%;
                                `}
                            >
                                No Visits Selected
                            </div>
                        )}
                    </div>
                </div>
                <div
                    css={css`
                        display: flex;
                        justify-content: space-between;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translate(-50%);
                        margin: 12px 0;
                        background: #fff;
                        width: calc(100% - 24px);
                        max-width: 1024px;
                    `}
                >
                    <Button
                        disabled={isBusy}
                        variant="secondary"
                        onClick={gotoScreenOne}
                        css={css`
                            width: 128px;
                        `}
                    >
                        Pay Allocation
                    </Button>
                    <Button
                        disabled={isBusy}
                        onClick={togglePreviewModal}
                        css={css`
                            width: 162px;
                        `}
                    >
                        Create all Checks
                    </Button>
                </div>
            </div>
            <EmployeeCheckPreviewModal
                show={showBulkPreview}
                onHide={onHide}
                loading={isBusy}
                submitting={submitting}
                onConfirm={() => onConfirmBulkCreateCheck()}
                previewData={bulkPreviewDataFromZeal}
                previewError={previewError}
                getTotalGrossPay={getTotalGrossPay}
            >
                {(bulkPreviewDataFromZeal || []).map((previewData, index) => (
                    <div
                        key={index}
                        css={css`
                            margin-top: 8px;
                        `}
                    >
                        <EmployeeCheckRow
                            employeeCheck={{
                                ...previewData,
                                ...previewData?.checks?.at(0),
                                totals: {
                                    employee_taxes: previewData?.total_employee_taxes,
                                    employer_taxes: previewData?.total_employer_taxes,
                                    employee_deductions: previewData?.total_employee_deductions,
                                    employer_deductions: previewData?.total_employer_deductions,
                                },
                            }}
                            setShowEmployeeCheck={() => {}}
                            companyID={companyID}
                            teamId={teamId}
                            preview={true}
                            bulk={true}
                            expand={index === 0}
                            loading={isBusy}
                            showShiftBreakDown={true}
                        />
                    </div>
                ))}
            </EmployeeCheckPreviewModal>
        </Fragment>
    );
}

function PayrollBillEmployeeDetails({
    detailVisit,
    defaultCheckDate,
    teamId,
    workLocations,
    changeStaffAmount,
    initialReportingData,
    bulkPreviewDataFromZeal,
    onUpdateEmployeesPreviewData,
    defaultReportingDataQuery = {},
    getTotalGrossPay,
    gotoScreenOne,
}) {
    const [isEmployeeDataModified, setIsEmployeeDataModified] = useState(false);
    const onUpdateEmployeeDataFromZeal = () => {
        setIsEmployeeDataModified(false);
    };
    const payrollType = detailVisit?.provider?.payroll?.type;
    const employeeID = detailVisit?.employee?.payroll?.metadata?.employeeID;
    useEffect(() => {
        const handleInput = (event, employeeID) => {
            setTimeout(() => setIsEmployeeDataModified(true), 1000);
        };

        const formContainer = document.querySelector(`#employee-payroll-form-container-${employeeID}`);
        if (formContainer) {
            const form = formContainer.querySelector('form.employee-payroll-form');
            if (form) {
                const inputHandler = (event) => handleInput(event, employeeID);
                form.addEventListener('input', inputHandler);

                return () => {
                    form.removeEventListener('input', inputHandler);
                };
            }
        }
    }, []);
    useEffect(() => {
        setIsEmployeeDataModified(false);
    }, [bulkPreviewDataFromZeal]);

    if (payrollType === 'zeal') {
        return (
            <ZealEmployeeCheck
                detailVisit={detailVisit}
                defaultCheckDate={defaultCheckDate}
                teamId={teamId}
                workLocations={workLocations}
                changeStaffAmount={changeStaffAmount}
                isEmployeeDataModified={isEmployeeDataModified}
                onUpdateEmployeeDataFromZeal={onUpdateEmployeeDataFromZeal}
                initialReportingData={initialReportingData}
                bulkPreviewDataFromZeal={bulkPreviewDataFromZeal}
                onUpdateEmployeesPreviewData={onUpdateEmployeesPreviewData}
                defaultReportingDataQuery={defaultReportingDataQuery}
                getTotalGrossPay={getTotalGrossPay}
                gotoScreenOne={gotoScreenOne}
            />
        );
    }
    return <div>Payroll process has not been set up for {payrollType}</div>;
}

function ZealEmployeeCheck({
    detailVisit,
    defaultCheckDate,
    teamId,
    workLocations,
    changeStaffAmount,
    isEmployeeDataModified,
    onUpdateEmployeeDataFromZeal,
    initialReportingData,
    bulkPreviewDataFromZeal,
    onUpdateEmployeesPreviewData,
    defaultReportingDataQuery = {},
    getTotalGrossPay,
    gotoScreenOne = () => {},
}) {
    const companyMetadata = detailVisit?.provider?.payroll?.metadata;
    const employeeMetadata = detailVisit?.employee?.payroll?.metadata;
    const companyOnboarded = companyMetadata?.companyID && companyMetadata?.status === 'live';
    const employeeOnboarded = isEmployeeOnboarded(detailVisit?.employee);

    const [isBusy, setIsBusy] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [previewData, setPreviewData] = useState();
    const [formData, setFormData] = useState();
    const [showPreviewData, setShowPreviewData] = useState(false);
    const onCreateCheckButton = (currentEmployeeFormData) => {
        setFormData(currentEmployeeFormData);
        togglePreviewModal();
    };
    const onHidePreviewModal = () => {
        setShowPreviewData(false);
    };

    const onCreateCheckConfirm = async () => {
        const result = await Swal.fire({
            title: 'Do you confirm creating check?',
            text: '',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#50aeb0',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        });
        if (result?.isConfirmed) {
            await createEmployeeCheck();
        }
    };

    async function createEmployeeCheck() {
        try {
            setSubmitting(true);
            await fetchWrapper.post(
                `evv/payroll/zeal/employee/${companyMetadata?.companyID}/${employeeMetadata?.employeeID}/checks${teamId ? `?team=${teamId}` : ''}`,
                formData
            );
            toast.success('Check created successfully!');
            gotoScreenOne();
        } catch (error) {
            toast.error(error.message || "Check couldn't be created");
        } finally {
            setSubmitting(false);
        }
    }

    async function getEmployeeCheckPreview(checkData) {
        try {
            setIsBusy(true);
            const data = await fetchWrapper.post(
                `/evv/payroll/zeal/employee/${companyMetadata?.companyID}/preview-check${teamId ? `?team=${teamId}` : ''}`,
                [checkData]
            );
            return data[employeeMetadata?.employeeID];
        } catch (error) {
            const errorMessage = error.message || "Preview data couldn't be fetched";
            toast.error(errorMessage);
        } finally {
            setIsBusy(false);
        }
    }
    const preparePreviewData = async (_previewData) => {
        if (!_previewData) {
            return;
        }
        const checkFormData = new FormData(
            document
                .querySelector(`#employee-payroll-form-container-${employeeMetadata?.employeeID}`)
                .querySelector('form.employee-payroll-form')
        );
        const checkData = prepareFormData('zeal', checkFormData);
        if (!checkData.reportingPeriodID) {
            return;
        }
        const totalHours =
            checkData?.shifts?.map((s) => (s.type === 'hourly' ? s.hours : 0))?.reduce((a, b) => 1 * a + 1 * b, 0) || 0;
        const grossPay = Number(_previewData?.checks?.at(0)?.gross_pay);
        const updatedPreviewData = { ...checkData, ..._previewData, totalHours, grossPay };
        setPreviewData(updatedPreviewData);
    };
    async function togglePreviewModal(event, toggleModal = true) {
        event?.stopPropagation();
        if (showPreviewData) {
            return;
        }
        const checkFormData = new FormData(
            document
                .querySelector(`#employee-payroll-form-container-${employeeMetadata?.employeeID}`)
                .querySelector('form.employee-payroll-form')
        );
        const checkData = prepareFormData('zeal', checkFormData);
        if (!checkData.reportingPeriodID) {
            return;
        }
        if (toggleModal) {
            setShowPreviewData((prev) => !prev);
        }
        const previewData = await getEmployeeCheckPreview(checkData);
        const totalHours =
            checkData?.shifts?.map((s) => (s.type === 'hourly' ? s.hours : 0))?.reduce((a, b) => 1 * a + 1 * b, 0) || 0;
        const grossPay = Number(previewData?.checks?.at(0)?.gross_pay);
        changeStaffAmount([previewData]);
        const updatedPreviewData = { ...checkData, ...previewData, totalHours, grossPay };
        setPreviewData(updatedPreviewData);
        onUpdateEmployeesPreviewData(updatedPreviewData);
        onUpdateEmployeeDataFromZeal();
    }

    useEffect(() => {
        const employeePreviewData = bulkPreviewDataFromZeal.find(
            (data) => data?.employeeID === employeeMetadata?.employeeID
        );
        if (employeePreviewData) {
            preparePreviewData(employeePreviewData);
        }
    }, [bulkPreviewDataFromZeal]);

    if (!workLocations?.length) {
        return <div>Preparing data...</div>;
    }

    if (!companyOnboarded) {
        return <div>The provider is not onboarded completely.</div>;
    }

    return (
        <OurAccordion
            title={
                <div
                    css={css`
                        width: calc(100% - 42px);
                    `}
                >
                    <div
                        css={css`
                            // display: flex;
                            gap: 60px;
                            justify-content: space-between;
                            margin-bottom: 12px;
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                gap: 12px;
                                // justify-content: space-between;
                                align-items: center;
                            `}
                        >
                            <span
                                css={css`
                                    font-size: larger;
                                `}
                            >
                                {detailVisit?.index + 1}.{' '}
                                {[previewData?.first_name, previewData?.middle_initial, previewData?.last_name]
                                    .filter((x) => x)
                                    .join(' ') ||
                                    (detailVisit?.employee?._id ? (
                                        detailVisit?.employee?.user?.name
                                    ) : (
                                        <span className="text-danger">Employee deleted</span>
                                    ))}
                                {employeeOnboarded ? (
                                    <span
                                        className="badge badge-sm badge-info"
                                        css={css`
                                            width: max-content;
                                            margin-left: 8px;
                                        `}
                                    >
                                        onboarded
                                    </span>
                                ) : (
                                    <span
                                        className="badge badge-sm badge-warning"
                                        css={css`
                                            width: max-content;
                                            margin-left: 8px;
                                        `}
                                    >
                                        Not onboarded
                                    </span>
                                )}
                            </span>
                            <div>
                                {employeeOnboarded && (
                                    <Button
                                        onClick={(e) => togglePreviewModal(e, false)}
                                        disabled={isBusy}
                                        css={css`
                                            background: #17a2b8;
                                            padding: 0px 8px;
                                            margin: 0;
                                            padding-bottom: 2px;
                                        `}
                                    >
                                        <TfiReload
                                            css={css`
                                                color: #fff;
                                            `}
                                            className={`${isBusy ? 'spin' : ''}`}
                                        />
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div
                        css={css`
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        `}
                    >
                        <div
                            css={css`
                                display: flex;
                                gap: 60px;
                                flex: 1;
                                margin-left: 8px;
                            `}
                        >
                            <PayHighlight
                                amount={(previewData?.totalHours || detailVisit?.totalHours || 0)?.toFixed(2)}
                                label="Total Hours"
                                dollars={false}
                                modified={isEmployeeDataModified}
                            />
                            <PayHighlight
                                amount={(
                                    previewData?.grossPay / previewData?.totalHours ||
                                    detailVisit?.totalAmount / detailVisit?.totalHours ||
                                    0
                                ).toFixed(2)}
                                label="Rate (Avg.)"
                                modified={isEmployeeDataModified || previewData?.error}
                            />
                            <PayHighlight
                                amount={previewData?.checks?.at(0)?.gross_pay || detailVisit?.totalAmount?.toFixed(2)}
                                label="Gross Pay"
                                modified={isEmployeeDataModified || previewData?.error}
                            />
                            <PayHighlight
                                amount={previewData?.checks?.at(0)?.net_pay || detailVisit?.totalAmount?.toFixed(2)}
                                label="Net Pay"
                                modified={isEmployeeDataModified || previewData?.error}
                            />
                            <PayHighlight
                                amount={previewData?.checks?.at(0)?.employee_taxes || '00.00'}
                                label="Employee Taxes"
                                modified={isEmployeeDataModified || previewData?.error}
                            />
                            <PayHighlight
                                amount={previewData?.checks?.at(0)?.employer_taxes || '00.00'}
                                label="Employer Taxes"
                                modified={isEmployeeDataModified || previewData?.error}
                            />
                        </div>
                    </div>
                    <CheckError
                        error={
                            employeeOnboarded
                                ? previewData?.error
                                : 'Check of this employee will not be created [Employee Not onboarded]'
                        }
                    />
                </div>
            }
            titleTag="sapn"
            defaultShow={true}
        >
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 12px;
                `}
            >
                <div
                    css={css`
                        display: flex;
                        flex-direction: column;
                        gap: 12px;
                    `}
                    id={`employee-payroll-form-container-${employeeMetadata?.employeeID}`}
                >
                    <ZealEmployeeCheckForm
                        companyID={companyMetadata?.companyID}
                        onSubmit={createEmployeeCheck}
                        isBusy={isBusy}
                        mode={employeeOnboarded ? 'create' : 'view'}
                        teamId={teamId}
                        defaultCheckDate={defaultCheckDate}
                        data={{
                            employeeID: employeeMetadata?.employeeID,
                            check_date: defaultCheckDate,
                            shifts: detailVisit?.shifts?.map((shift) => ({
                                type: shift?.type,
                                hours: shift?.totalHours?.toFixed(2) || 0,
                                amount: shift?.amount?.toFixed(2) || 0,
                                wage: shift?.rate,
                                time: shift?.end,
                                custom_name: `${shift?.visitId || ''}`,
                                reimbursementcustom_name: `${shift?.visitId || ''}`,
                                work_site_id: detailVisit?.client?.identifier,
                            })),
                        }}
                        workLocations={workLocations}
                        initialReportingData={initialReportingData}
                        onCreateCheckButton={onCreateCheckButton}
                        defaultReportingDataQuery={defaultReportingDataQuery}
                    />
                    <EmployeeCheckPreviewModal
                        show={showPreviewData}
                        onHide={onHidePreviewModal}
                        onConfirm={() => onCreateCheckConfirm()}
                        loading={isBusy}
                        submitting={submitting}
                        previewData={[previewData]}
                        getTotalGrossPay={getTotalGrossPay}
                    >
                        <EmployeeCheckRow
                            employeeCheck={{
                                ...previewData,
                                ...previewData?.checks?.at(0),
                                totals: {
                                    employee_taxes: previewData?.total_employee_taxes,
                                    employer_taxes: previewData?.total_employer_taxes,
                                    employee_deductions: previewData?.total_employee_deductions,
                                    employer_deductions: previewData?.total_employer_deductions,
                                },
                            }}
                            setShowEmployeeCheck={() => {}}
                            companyID={companyMetadata?.companyID}
                            teamId={teamId}
                            preview={true}
                            loading={isBusy}
                            showShiftBreakDown={true}
                        />
                    </EmployeeCheckPreviewModal>
                </div>
            </div>
        </OurAccordion>
    );
}

export default PayrollBillModal;
