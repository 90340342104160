import React, { useState, useEffect } from 'react';
import { css } from 'twin.macro';
import { Tab, Tabs } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import { fetchWrapper } from '../../../_helpers/fetchWrapper';
import { getTeamIdFromURL, getChannelIdFromSettingsURL } from '../../../_helpers';
import { ContentContainerStyles, EVVContainerStyles } from './styles/EVVContainerStyles';
import { SET_EVVSTATE, SET_EVVFORMS } from '../../../_redux/actions/ActionTypes';
import UserClient from './Client/UserClient';
import UserVisit from './Visit/UserVisit';
import PayAllocation from './Payroll/PayAllocation';
import Payrolls from './Payroll/Payrolls';
import { getRole } from '../../../_helpers/roles/get-roles';
import Provider from './Provider';
import Staff from './Staff';
import Reports from './Payroll/Reports';

const UserEVVContainer = ({ channelDetails, currUser, teams }) => {
    const teamId = getTeamIdFromURL();
    const channelIdFromUrl = getChannelIdFromSettingsURL();

    const [selectedPage, setSelectedPage] = useState(channelIdFromUrl ? 'Visit' : 'Provider');
    const [canViewEVV, setCanViewEVV] = useState(false);

    const dispatch = useDispatch();

    const Content =
        {
            Provider,
            Staff,
            Client: UserClient,
            Visit: UserVisit,
            PayAllocation,
            Payrolls,
            Reports,
        }[selectedPage] || Provider;

    useEffect(() => {
        const getEvvStateData = async () => {
            try {
                const response = await fetchWrapper.get(`/evv/state?${teamId ? `&team=${teamId}` : ''}`);
                if (response?.state) {
                    dispatch({
                        type: SET_EVVSTATE,
                        payload: response?.state,
                    });
                }
            } catch (err) {
                console.log(err);
            }
        };

        const getEvvFormData = async () => {
            try {
                const response = await fetchWrapper.get(`/evv/forms?${teamId ? `&team=${teamId}` : ''}`);
                if (response) {
                    dispatch({
                        type: SET_EVVFORMS,
                        payload: response,
                    });
                }
            } catch (err) {
                console.log(err);
            }
        };

        getEvvStateData();
        getEvvFormData();
    }, [dispatch]);

    // useEffect(()=>{
    // 	if(channelIdFromUrl && channelDetails?.id && !channelDetails?.client){
    // 		window.location.href = '/not-found';
    // 	}
    // },[channelIdFromUrl,channelDetails?.id])
    useEffect(() => {
        let team = teams.filter((team) => team.id === teamId);
        let role = getRole(currUser, team[0]);
        if (role === 'admin' || role === 'teamManager' || role === 'owner') {
            setCanViewEVV(true);
        }
    }, [currUser, teams]);

    if (!canViewEVV && channelIdFromUrl) {
        return null;
    }
    return (
        <EVVContainerStyles>
            <h1 className="mb-4">EVV & Payroll</h1>
            <div
                className="d-flex flex-column"
                css={css`
                    min-height: 800px;
                `}
            >
                <Tabs
                    id="controlled-tab-example"
                    activeKey={selectedPage}
                    onSelect={(selectedKey) => setSelectedPage(selectedKey)}
                    className="mb-0"
                >
                    {!!channelIdFromUrl || <Tab eventKey="Provider" title="Provider"></Tab>}
                    {!!channelIdFromUrl || <Tab eventKey="Staff" title="Staff"></Tab>}
                    <Tab eventKey="Client" title="Client"></Tab>
                    <Tab eventKey="Visit" title="Visit"></Tab>
                    <Tab eventKey="PayAllocation" title="Pay Allocation"></Tab>
                    <Tab eventKey="Payrolls" title="Payrolls"></Tab>
                    <Tab eventKey="Reports" title="Reports"></Tab>
                </Tabs>
                <ContentContainerStyles>
                    <Content teamId={teamId} channelDetails={channelDetails} />
                </ContentContainerStyles>
            </div>
        </EVVContainerStyles>
    );
};

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
    currUser: state.user.user,
});

export default connect(mapStateToProps, {})(UserEVVContainer);
