import FormPickerItem from './FormPickerItem';
import ID from '../../../_helpers/uuid';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// icons
import { BiHeading, BiTimeFive } from 'react-icons/bi';
import {
	FaUserCircle,
	FaCalendarAlt,
	FaParagraph,
	FaFile,
	FaImage,
	FaPlayCircle,
	FaRegFileAudio,
} from 'react-icons/fa';

import {
	MdEmail,
	MdRadioButtonChecked,
	MdCheckBox,
	MdArrowDropDownCircle,
	MdPhotoFilter,
	MdOutlinePictureAsPdf,
} from 'react-icons/md';

import { RiInputMethodFill } from 'react-icons/ri';
import { VscFilePdf } from 'react-icons/vsc';
import { formPickerHeader } from './styles/FormPickerStyles';

export default function FormPicker({ teamId, openFormBuilder }) {
	const history = useHistory();
	const items = defaultItems();

	const dispatch = useDispatch();

	function defaultItemOptions(element) {
		switch (element) {
			case 'Select':
				return [
					{ text: 'option 1', value: ID.uuid() },
					{ text: 'option 2', value: ID.uuid() },
					{ text: 'option 3', value: ID.uuid() },
				];
			case 'Checkbox':
				return [
					{
						text: 'option 1',
						value: ID.uuid(),
					},
					{
						text: 'option 2',
						value: ID.uuid(),
					},
					{
						text: 'option 3',
						value: ID.uuid(),
					},
				];
			case 'Radio':
				return [
					{
						text: 'option 1',
						value: ID.uuid(),
					},
					{
						text: 'option 2',
						value: ID.uuid(),
					},
					{
						text: 'option 3',
						value: ID.uuid(),
					},
				];
			default:
				return [];
		}
	}

	function defaultItems() {
		return [
			{
				key: 'Header',
				type: 'textbox',
				name: 'Header Text',
				label: 'Header Text',
				icon: <BiHeading />,
				instruction: 'Header Text',
			},
			{
				key: 'FullName',
				name: 'Full Name',
				label: 'Full Name',
				type: 'textbox',
				icon: <FaUserCircle />,
				data: [
					{ key: 'firstName', name: 'First Name', type: 'text' },
					{ key: 'lastName', name: 'Last Name', type: 'text' },
				],
			},
			{
				key: 'Email',
				name: 'Email',
				label: 'Email',
				type: 'textbox',
				icon: <MdEmail />,
			},
			{
				key: 'InputField',
				name: 'InputField',
				label: 'Enter your Question',
				type: 'textbox',
				icon: <RiInputMethodFill />,
			},
			{
				key: 'Paragraph',
				name: 'Paragraph',
				label: 'Paragraph',
				icon: <FaParagraph />,
				type: 'textbox',
				instruction:
					'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem perferendis rerum facilis molestias nesciunt labore quas quos aspernatur totam sint laudantium sunt a magnam, iure saepe, minima eos eaque qui.',
			},
			{
				key: 'Select',
				name: 'Select',
				label: 'Choose a option!',
				icon: <MdArrowDropDownCircle />,
				type: 'select',
				options: [],
			},
			{
				key: 'Checkbox',
				name: 'Checkbox',
				label: 'Multiple select option!',
				type: 'checkbox',
				icon: <MdCheckBox />,
				options: [],
			},
			{
				key: 'Radio',
				name: 'Radio',
				type: 'radio',
				label: 'Single select option!',
				icon: <MdRadioButtonChecked />,
				options: [],
			},
			{
				key: 'Signature',
				name: 'Signature',
				label: 'Signature',
				icon: <FaFile />,
				type: 'signature',
			},
			{
				key: 'DatePicker',
				name: 'Date',
				label: 'Date',
				type: 'date',
				icon: <FaCalendarAlt />,
			},
			{
				key: 'TimePicker',
				name: 'Time',
				label: 'Time',
				type: 'time',
				icon: <BiTimeFive />,
			},
			{
				key: 'DateTimePicker',
				name: 'DateTime',
				label: 'DateTime',
				type: 'datetime',
				icon: <FaCalendarAlt />,
			},
			{
				key: 'Image',
				name: 'Image Input',
				label: 'Image',
				type: 'file',
				icon: <FaImage />,
			},
			{
				key: 'File',
				name: 'File',
				label: 'File',
				type: 'file',
				icon: <VscFilePdf />,
			},
			{
				key: 'AudioFile',
				name: 'AudioFile',
				label: 'AudioFile',
				type: 'audio',
				icon: <FaRegFileAudio />,
			},
			{
				key: 'Link',
				name: 'Link',
				label: 'Link:',
				type: 'link',
				icon: <FaPlayCircle />,
				instruction: 'Enter video link source in the Url Field by clicking edit icon',
			},
			{
				key: 'Photo',
				name: 'Preview Photo',
				label: 'Photo',
				type: 'photo',
				icon: <MdPhotoFilter />,
				instruction: 'Upload image in edit section, click edit icon',
			},
			{
				key: 'PdfPreviewer',
				name: 'PdfPreviewer',
				label: 'Pdf Previewer',
				type: 'pdf',
				icon: <MdOutlinePictureAsPdf />,
				instruction: 'Upload pdf in edit section, click edit icon',
			},
		];
	}

	function _onClick(item) {
		var elementOptions = {
			id: ID.uuid(),
			key: item.key,
			fieldName: item.label,
			type: item.type,
		};
		if (item.instruction) elementOptions['instruction'] = item.instruction;

		if (item.data) elementOptions['data'] = item.data;

		if (item.options) {
			elementOptions['data'] = defaultItemOptions(elementOptions['key']);
		}
		dispatch({ type: 'ADD_FORM_DATA', payload: elementOptions });
	}
	// const handleClose = () => {
	// 	if (teamId) {
	// 		history.push('/form-library');
	// 	} else {
	// 		history.push('/master-form-library');
	// 	}
	// };

	const handleOpen = () => {
		history.push('/form-builder/create');
	};

	return (
		<div>
			<div css={formPickerHeader} className="d-flex flex-row justify-content-between">
				<h5
					onClick={() => {
						if (!openFormBuilder) return handleOpen();
						else return null;
					}}
				>
					Form Elements
				</h5>
				{/* <div css={formBuilderClose}
					
					onClick={() => {
						if (openFormBuilder) return handleClose();
						else return null;
					}}
				>
					{openFormBuilder && <MdClose/>}
				</div> */}
			</div>
			<ul>
				{openFormBuilder &&
					items.map((item) => {
						return <FormPickerItem data={item} key={item.key} onClick={() => _onClick(item)} />;
					})}
			</ul>
		</div>
	);
}
