import { useEffect, useState } from 'react';
import { css } from 'twin.macro';
import {
    CashRequirements,
    PayrollJournal,
    LaborAllocation,
    Paystubs,
    WorkerSummary,
    PaymentSummary,
    KYCSummary,
    DeductionsSummary,
    CustomPayrollJournal,
} from './ReportTypes';
const ZealReports = ({ provider, dateFilters, selectedReportOption }) => {
    const companyID = provider?.metadata?.companyID;
    const Content = {
        'cash-requirements': CashRequirements,
        'payroll-journal': PayrollJournal,
        'custom-payroll-journal': CustomPayrollJournal,
        'labor-allocation': LaborAllocation,
        paystubs: Paystubs,
        'worker-summary': WorkerSummary,
        'payment-summary': PaymentSummary,
        'kyc-summary': KYCSummary,
        'deductions-summary': DeductionsSummary,
    }[selectedReportOption?.value];

    if (!selectedReportOption.value) {
        return (
            <div
                css={css`
                    text-align: center;
                `}
            >
                Please select Report Type
            </div>
        );
    }

    return (
        <div
            css={css`
                display: flex;
                flex-direction: column;
            `}
        >
            <div
                css={css`
                    font-weight: 700;
                    font-size: 2rem;
                `}
            >
                {selectedReportOption?.label}
            </div>
            <div>{selectedReportOption?.description}</div>
            <hr />
            <Content
                companyID={companyID}
                slug={selectedReportOption?.value}
                // handleDownloadLink={handleDownloadLink}
                // downloadLink={downloadLink}
                dateFilters={dateFilters}
            />
        </div>
    );
};

export default ZealReports;
